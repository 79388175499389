
<div>
    <div class="inhoit">
        <div class="row">
            <div class="col s12 m6 xl8" style="padding-left: 0; padding-right: 0;">
                <div *ngIf="cookies(); else noCookies" class="mapouter" [style.height]="mapheight">
                    <div class="gmap_canvas">
                        <iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                         src="https://maps.google.com/maps?width=100%&amp;height=100%&amp;hl=de&amp;q=Biohof%20Huama,%20Untergeng%2078,%204201%20Untergeng&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                    </div>
                </div>

                <ng-template #noCookies>
                    <p class="flow-text" style="text-align: center; font-style: italic;">Bitte Cookies akzeptieren um Karte anzuzeigen</p>
                </ng-template>
                
            </div>
            <div class="col s12 m6 xl4">
                <div id="kontakt">
                    <h3>Kontakt</h3>
                    <p class="flow-text">
                        Biohof Huama - Familie Nopp<br>
                        Untergeng 78<br>
                        4201 Eidenberg<br>
                    </p>
                    <a *ngIf="maps" [href]="maps" class="waves-effect waves-light btn secondary huama">Maps</a>

                </div>
                <div id="anfahrt">
                    <p class="flow-text">
                        <a href="mailto:office@biohof-huama.at">office@biohof-huama.at</a><br>
                        <a *ngIf="isMobile" href="tel:+436644021176">+43 664 4021176</a><!--ruf mi au pls-->
                        <span *ngIf="!isMobile">+43 664 4021176</span>
                    </p>
                </div>
        </div>
        </div>
    </div>
</div>