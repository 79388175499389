<div class="logo-container center valign-wrapper" [ngClass]="{'mobile': isMobile}">

    <huama-logo></huama-logo>
</div>
    <div class="container">
        <div class="row koatn" [ngClass]="{'valign-wrapper': !isMobile}">
            <div class="col s12 m6 l7 nopadding" *ngIf="isMobile">
                <img class="image  abrundn-rechts"  width="100%" src="assets/home/foto1.webp"/>
            </div>
            <div class="col s12 m6 l5 center">
                <h2 class="huamerisch">Das sind wir!</h2>
                <p class="flow-text">
                    Florian & Melanie <br>
                    Rosi & Leo

                </p>
                <a  [ngClass]="{'buttonMobile': isMobile}" routerLink="/about" class="waves-effect waves-light btn secondary huama">Über uns</a>
            </div>
            <div class="col s12 m6 l7 nopadding" *ngIf="!isMobile">
                <img class="image  abrundn-rechts"  width="100%" src="assets/home/foto1.webp"/>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row koatn" [ngClass]="{'valign-wrapper': !isMobile}">
            
            <div class="col s12 m6 l7 nopadding">
                <img class="image abrundn-links" width="100%" src="assets/home/foto2.webp"/>
            </div>
            <div class="col s12 m6 l5 center">
                <h2 class="huamerisch">Unser Hof</h2>
                <p class="flow-text">
                    Mitten im schönen Mühlviertel.<br>
In der ruhigen Ortschaft Untergeng.<br>
Mit purer Lebensqualität.

                </p>
                <a  [ngClass]="{'buttonMobile': isMobile}" routerLink="/hof" class="waves-effect waves-light btn secondary huama">Unser Hof</a>
                <br>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row koatn" [ngClass]="{'valign-wrapper': !isMobile}">
            <div class="col s12 m6 l7 nopadding" *ngIf="isMobile">
                <img class="image abrundn-rechts"  width="100%" src="assets/home/foto4.webp"/>
            </div>
            <div class="col s12 m6 l5 center">
                
                <h2 class="huamerisch">Unsere Produkte!</h2>
                <p class="flow-text">
                    Eier, Nudeln, Rindfleisch, Kartoffel, Knoblauch und vieles mehr. 
                </p>
                <a [ngClass]="{'buttonMobile': isMobile}" routerLink="/produkte" class="waves-effect waves-light btn secondary huama">Produkte</a>

            </div>
            <div class="col s12 m6 l7 nopadding" *ngIf="!isMobile">
                <img class="image abrundn-rechts"  width="100%" src="assets/home/foto4.webp"/>
            </div>
        </div>
    </div>


<huama-anfahrt></huama-anfahrt>
