
<div class="container">
    <div class="row">
        <div class="col s12">
            <div class="koatn" id="kontakt">
                <h3>Kontakt</h3>
                <p class="flow-text">
                    Biohof Huama - Familie Nopp<br>
                    Untergeng 78<br>
                    4201 Eidenberg<br>
                </p>
            </div>
            <br>
            <div class="koatn" id="betriebsnummer">
                <p class="flow-text" *ngIf="impressum">
                    Betriebsnummer: 2377217
                </p>
            </div>
            <br>
            <div class="koatn" id="anfahrt">
                <p class="flow-text">
                    <a href="mailto:office@biohof-huama.at">office@biohof-huama.at</a><br>
                    <a *ngIf="isMobile" href="tel:+436644021176">+43 664 4021176</a><!--ruf mi au pls-->
                    <span *ngIf="!isMobile">+43 664 4021176</span>
                </p>
            </div>
    </div>
    </div>
</div>