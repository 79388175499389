
<div class="container">
    <div class="row">
        <div class="col s12 m10 l8 offset-l2 offset-m1 center  huamerisch">
            <div class="titl">
                <div class="koatn">
                    <h1>{{(page| async)?.title}}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        
        <div class="col s12 m10 l8 offset-l2 offset-m1 nopadding abrundn-links abrundn-rechts">
            <img class="product-image abrundn-rechts"  width="100%" [src]="(page | async)?.img"/>
        </div>
    </div>
    <div class="row" [ngClass]="{'valign-wrapper': !isMobile}">
        <div class="koatn col s12 m10 l8 offset-l2 offset-m1 center section">
            <p class="flow-text" *ngFor="let absatz of (page | async)?.text">
                {{absatz}}
            </p>

        </div>
        
    </div>
</div>

<div class="container products">
    <div *ngFor="let pr of (produkte | async)" class="row koatn" [ngClass]="{'valign-wrapper': !isMobile}">
        
        <div  class="col s12 l6 xl5 nopadding">
            <img *ngIf="pr.img" [ngClass]="{'wegIsts': !pr.available}" class="product-image abrundn-links" width="100%" [src]="pr.img"/>
        </div>
        <div  class="col s12 l6 xl7">
            <div  class="text-col section">
                <h3 *ngIf="pr.available" class="huamerisch">{{pr.name}}</h3>
                <h3 *ngIf="!pr.available" class="huamerisch"><del>{{pr.name}}</del> (ausverkauft)</h3>
            <p *ngFor="let absatz of pr.text">
                {{absatz}}
            </p>
            <p *ngIf="!pr.available && pr.unavailableText">
                {{pr.unavailableText}}
            </p>
            </div>
        </div>
    </div>
    <div class="row " [ngClass]="{'valign-wrapper': !isMobile}">
        <div *ngFor="let pr of (produkteOhneFoto | async)" class="col s12 l6 nopic" [ngClass]="{'mobile': isMobile}">
            <div class="section text-col koatn ">

                <h3 *ngIf="pr.available" class="huamerisch">{{pr.name}}</h3>
                <h3 *ngIf="!pr.available" class="huamerisch"><del>{{pr.name}}</del> (ausverkauft)</h3>
                <p *ngFor="let absatz of pr.text">
                    {{absatz}}
                </p>

                <p *ngIf="!pr.available && pr.unavailableText">
                    {{pr.unavailableText}}
                </p>
            </div>
        </div>
    </div>    
</div>

<div *ngIf="false" class="container products">
    
</div>