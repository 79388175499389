import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'huama-blank',
  template: `
  <div class="container">
    <div class="row">
      <div class="col">
      <br>
      </div>
    </div>
  </div>`,
  styles: []
})
export class BlankComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
