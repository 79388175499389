import { Component, OnInit } from '@angular/core';
import { FaConfig } from '@fortawesome/angular-fontawesome';
import { isMobileDevice, isScrolledDownEnough } from '../helpers';
import { Subject, fromEvent, takeUntil, filter } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'huama-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  private _transparentNav: boolean = true;
  footerFixed = true;
  destroy = new Subject<void>();
  destroy$ = this.destroy.asObservable();

  maps;
  

  get transparentNav(): string | null {
    let b;
    if (this.isMobile) {
      b = this._transparentNav ? null : 'none';
    } else {
      b = this._transparentNav ? 'none' : null;
    }
    return b;
  }

  get showImprint(): string | null {
    return this._transparentNav ? '0' : '1'
  }
  
  set transparentNav(y: any) {
    this._transparentNav = y === undefined || y === null || isScrolledDownEnough(y as number) ;
  }

  set showImprint(y : any)  {}

  constructor(faConfig: FaConfig,
    router: Router) { 
    this.transparentNav = this.isMobile ? 'none' : undefined;
    faConfig.defaultPrefix = "fab";
    this.maps = this.getDestinationLink();
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
  )
      .subscribe(event => {
          switch((event as NavigationEnd).url) {
            case "/":
            case "/#":
            case "/#kontakt":
              this.footerFixed = true;
            break;
            default:
              this.footerFixed = this.isMobile || false;
          } 
      });
    fromEvent(window, 'scroll').pipe(takeUntil(this.destroy$))
			.subscribe((e: Event) => {
        const y = this.getYPosition(e);
        const fromTop = (e.target as any)?.scrollingElement?.scrollHeight;
        const clientHeight = (e.target as any)?.scrollingElement?.clientHeight;
        const calcBottom = fromTop && clientHeight && y && y > 0 ? Number(fromTop) - Number(clientHeight) - y : undefined;
        this.transparentNav = this.isMobile ? y : calcBottom;
      })
  }

  ngOnInit(): void {
  }

  isMobile = isMobileDevice(navigator, "l")

  getYPosition(e: Event): number | undefined {
    return (e.target as any)?.scrollingElement?.scrollTop;
  }

  getDestinationLink() {
    // If it's an iPhone..
    if( (navigator.platform.indexOf("iPhone") != -1) 
        || (navigator.platform.indexOf("iPod") != -1)
        || (navigator.platform.indexOf("iPad") != -1))
         return "maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=Biohof%20Huama,%20Untergeng%2078,%204201%20Untergeng";
    return"https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=Biohof%20Huama,%20Untergeng%2078,%204201%20Untergeng";
  }
}
