<nav [style.background]="transparentNav" [style.box-shadow]="transparentNav"  class="footer" [ngClass]="{'footerFixed': footerFixed}">
  <div class="container">
    <div class="row">
      <div class="col s10 offset-s1 m8 offset-m2 l6 offset-l3 xl4 offset-xl4">
        <div class="wrapper">
          <a href="https://www.instagram.com/biohofhuama/">
            <fa-icon icon="instagram"></fa-icon>
          </a>
          <a href="https://www.facebook.com/BiohofHuama">
            <fa-icon icon="facebook"></fa-icon>
          </a>
          <a [href]="maps">
            <fa-icon [icon]="['fas', 'map']"></fa-icon>
          </a>
        </div>

      </div>
      <div [style.opacity]="showImprint" class="col imprint" >
        <a routerLink="/imprint" style="float: right;">Impressum</a>
      </div>

    </div>
  </div>
  </nav>

  <nav *ngIf="footerFixed" class="footer fake">

  </nav>