import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AboutComponent } from './about/about.component';
import { HofComponent } from './hof/hof.component';
import { HomeComponent } from './home/home.component';
import { ProductsComponent } from './products/products.component';
import { SoonComponent } from './soon/soon.component';
import { ImpressumComponent } from './impressum/impressum.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
  }, {
    path: 'about',
    component: AboutComponent,
  }, {
    path: 'hof',
    component: HofComponent,
  }, {
    path: 'produkte',
    component: ProductsComponent,
  }, {
    path: 'soon',
    component: SoonComponent,
  }, {
    path: 'imprint',
    component: ImpressumComponent,
  }, {
    path: '**',
    redirectTo: '',
  }
];

const soon: Routes = [
  {
    path: '**',
    component: SoonComponent,
  }
]

@NgModule({
  imports: [RouterModule.forRoot(environment.production ? soon : routes,  {
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
