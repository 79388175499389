import { AfterContentChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { isMobileDevice } from './helpers';
import { LinkInstance } from './nav/nav.component';
import { Snow } from './snow';


@Component({
  selector: 'huama-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, AfterContentChecked, OnInit {
  title = 'biohof-huama';

  navItems: Array<{text: string} & LinkInstance> = [
    {
      text: 'Home',
      routerLink: ['/'],
      fragment: '',
    }, {
      text: 'Über uns',
      routerLink: ['/', 'about'],
    },{
      text: 'Unser Hof',
      routerLink: ['/', 'hof'],
    }, {
      text: 'Produkte',
      routerLink: ['/', 'produkte'],
    }, {
      text: 'Kontakt',
      routerLink: ['/'],
      fragment: 'kontakt',
    }, 
  ];

  showToolbar = true;

  addedOnce = false;

  snow?: Snow;

  get cookiesAccepted() {
    const cookies = localStorage.getItem("cookies");
    return cookies !== null ? JSON.parse(cookies) as boolean : false
  }

  set cookiesAccepted(cookie: boolean) {
    localStorage.setItem("cookies", JSON.stringify(cookie));
  }

  constructor() {

  }
  ngOnInit(): void {
    const date = new Date(Date.now());

    if ((date.getMonth() == 11 || date.getMonth() == 0) && !this.snow) {
      this.snow = new Snow(document, {id: "snow",
      mobile: this.isMobile()})
    }
  }
  ngAfterContentChecked(): void {
    if(!this.addedOnce) {
      this.addedOnce = true;

      if (this.snow && !this.snow.go) {
        this.snow.start();
      }
    
    }
  }

  ngOnDestroy(): void {
    
  }

  isMobile = () => isMobileDevice(navigator)

  toast(cookie: boolean) {
    this.cookiesAccepted = cookie;
    if (cookie === false) {
      window.location.href = "https://www.google.at/search?q=biohof+huama+untergeng";
    }
  }

}
