import { Component, OnInit } from '@angular/core';
import { isMobileDevice } from '../helpers';



@Component({
  selector: 'huama-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  isMobile = isMobileDevice(navigator);

}
