<div class="container">
    <div class="row">
        <div class="col s12">
            <div class="koatn" id="impressum">
                <h2>Impressum</h2>
            </div>
        </div>
    </div>
</div>

<huama-kontakt [impressum]="true"></huama-kontakt>

<div class="container">
    <div class="row">
        <div class="col s12 m10 offset-m1 l6">
            <div class="koatn">
                <h3>Haftung für Inhalte</h3>
                <p>
                    Als Diensteanbieter sind wir für eigene Inhalte auf diesen Seiten nach
                    den allgemeinen Gesetzen verantwortlich. Als Diensteanbieter jedoch
                    nicht verpflichtet, übermittelte oder gespeicherte fremde
                    Informationen zu überwachen oder nach Umständen zu forschen, die auf
                    eine rechtswidrige Tätigkeit hinweisen.
                </p>
                <p>
                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                    Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
                    Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                    Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
                    von entsprechenden Rechtsverletzungen werden wir diese Inhalte
                    umgehend entfernen.
                </p>
            </div>
        </div>
        <div class="col s12 m10 offset-m1 l6">
            <div class="koatn">
                <h3>Haftung für Links</h3>
                <p>
                    Unser Angebot enthält Links zu externen Websites Dritter, auf deren
                    Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
                    fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                    verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
                    Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
                    Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
                    Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                </p>
                <p>
                    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
                    ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                    Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                    umgehend entfernen.
                </p>
            </div>
        </div>
        <div class="col s12 m10 offset-m1 l6">
            <div class="koatn">
                <h3>Urheberrecht</h3>
                <p>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
                    Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
                    Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                    Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
                    jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
                    sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                </p>
                <p>
                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
                    wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
                    Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
                    eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                    entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                    werden wir derartige Inhalte umgehend entfernen. entfernen.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col s12">
            <div class="koatn" id="dataprivacy">
                <h2>Datenschutz</h2>
                <p></p>
            </div>
        </div>
        <div class="col s12 m10 offset-m1 l6">
            <div class="koatn">
                <h3>Datenerfassung auf unserer Website</h3>
                <h4>
                    Wer ist verantwortlich für die Datenerfassung auf dieser Website?
                </h4>
                <p>
                    Die Datenverarbeitung auf dieser Website erfolgt durch den
                    Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser
                    Website entnehmen.
                </p>
                <h4>Wie erfassen wir Ihre Daten?</h4>
                <p>
                    Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
                    mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein
                    Kontaktformular eingeben.
                </p>
                <p>
                    Andere Daten werden automatisch beim Besuch der Website durch unsere
                    IT-Systeme erfasst. Das sind vor allem technische Daten (z.B.
                    Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
                    Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website
                    betreten.
                </p>

                <h4>Wofür nutzen wir Ihre Daten?</h4>
                <p>
                    Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
                    der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
                    Nutzerverhaltens verwendet werden.
                </p>
                <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
                <p>
                    Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
                    Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
                    erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung
                    oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren
                    Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im
                    Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen
                    ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                </p>
            </div>
        </div>
        <div class="col s12 m10 offset-m1 l6">
            <div class="koatn">
                <h3>Analyse-Tools und Tools von Drittanbietern</h3>
                <p>
                    Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch
                    ausgewertet werden. Das geschieht vor allem mit Cookies und mit
                    sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens
                    erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen
                    zurückverfolgt werden. Sie können dieser Analyse widersprechen oder
                    sie durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte
                    Informationen dazu finden Sie in der folgenden Datenschutzerklärung.
                </p>
                <p>
                    Sie können dieser Analyse widersprechen. Über die
                    Widerspruchsmöglichkeiten werden wir Sie in dieser
                    Datenschutzerklärung informieren.
                </p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col s12 m10 offset-m1 l6">
            <div class="koatn">
                <h3>Datenschutz</h3>
                <p>
                    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
                    sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
                    entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
                    Datenschutzerklärung.
                </p>
                <p>
                    Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
                    Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
                    persönlich identifiziert werden können. Die vorliegende
                    Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
                    sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
                    geschieht.
                </p>
                <p>
                    Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
                    der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
                    lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                    möglich.
                </p>
            </div>
        </div>

        <div class="col s12 m10 offset-m1 l6">
            <div class="koatn">
                <h3>Hinweis zur verantwortlichen Stelle</h3>
                <p>
                    Die verantwortliche Stelle für die Datenverarbeitung auf dieser
                    Website ist:
                </p>
                <p>
                    Biohof Huama<br />
                    Untergeng 78, A-4201 Eidenberg
                </p>
                <p>
                    Telefon: +43 664 4021176 <br />
                    E-Mail: office@biohof-huama.at
                </p>
                <p>
                    Verantwortliche Stelle ist die natürliche oder juristische Person, die
                    allein oder gemeinsam mit anderen über die Zwecke und Mittel der
                    Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen
                    o. Ä.) entscheidet.
                </p>
            </div>
        </div>
        <div class="col s12 m10 offset-m1 l6">
            <div class="koatn">
                <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
                <p>
                    Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
                    Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
                    jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail
                    an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
                    Datenverarbeitung bleibt vom Widerruf unberührt.
                </p>
            </div>
        </div>
        <div class="col s12 m10 offset-m1 l6">
            <div class="koatn">
                <h3>Recht auf Datenübertragbarkeit</h3>
                <p>
                    Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
                    oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
                    oder an einen Dritten in einem gängigen, maschinenlesbaren Format
                    aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
                    einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
                    technisch machbar ist.
                </p>
            </div>
        </div>

        <div class="col s12 m10 offset-m1 l6">
            <div class="koatn">
                <h3>SSL- bzw. TLS-Verschlüsselung</h3>
                <p>
                    Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
                    Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
                    Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
                    TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
                    daran, dass die Adresszeile des Browsers von “http://” auf “https://”
                    wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                </p>
                <p>
                    Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
                    Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
                    werden.
                </p>
            </div>
        </div>

        <div class="col s12 m10 offset-m1 l6">
            <div class="koatn">
                <h3>Auskunft, Sperrung, Löschung</h3>
                <p>
                    Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
                    das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
                    personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck
                    der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung
                    oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
                    personenbezogene Daten können Sie sich jederzeit unter der im
                    Impressum angegebenen Adresse an uns wenden.
                </p>
            </div>
        </div>
        <div class="col s12 m10 offset-m1 l6">
            <div class="koatn">
                <h3>Widerspruch gegen Werbe-Mails
                </h3>
                <p>
                    Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von
                    nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die
                    Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten
                    Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
                </p>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col s12 m10 offset-m1 l6">
            <div class="koatn">
                <h3>Cookies</h3>
                <p>
                    Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen
                    Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher,
                    effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt
                    werden und die Ihr Browser speichert.
                </p>
                <p>
                    Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende
                    Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie
                    diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch
                    wiederzuerkennen.
                </p>
                <p>
                    Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und
                    Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell
                    ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei
                    der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
                </p>
                <p>
                    Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung
                    bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf
                    Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes
                    Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung
                    seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens)
                    gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.
                </p>
            </div>
        </div>
        <div class="col s12 m10 offset-m1 l6">
            <div class="koatn">
                <h3>Server-Log-Dateien</h3>
                <p>
                    Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
                    Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt.
                    Dies sind:
                </p>
                <ul class="browser-default">
                    <li>
                        Browsertyp und Browserversion
                    </li>
                    <li>
                        verwendetes Betriebssystem
                    </li>
                    <li>
                        Referrer URL
                    </li>
                    <li>
                        Hostname des zugreifenden Rechners
                    </li>
                    <li>
                        Uhrzeit der Serveranfrage
                    </li>
                    <li>
                        IP-Adresse
                    </li>
                </ul>
                <p>
                    Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
                </p>
                <p>
                    Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten
                    zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
                </p>
            </div>
        </div>
        <div class="col s12 m10 offset-m1 l6">
            <div class="koatn">
                <h3>Kontaktformular
                </h3>
                <p>
                    Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
                    Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage
                    und für den Fall von Anschlussfragen bei uns
                    gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                </p>
                <p>
                    Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit ausschließlich auf
                    Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit
                    widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum
                    Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
                </p>
                <p>
                    Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung
                    auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung
                    entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen –
                    insbesondere Aufbewahrungsfristen – bleiben unberührt.
                </p>
            </div>
        </div>
        <div class="col s12 m10 offset-m1 l6">
            <div class="koatn">
                <h3>Verarbeiten von Daten (Kunden und Vertragsdaten)
                </h3>
                <p>
                    Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie für die Begründung,
                    inhaltliche Ausgestaltung oder Änderung des Rechtsverhältnisses erforderlich sind (Bestandsdaten).
                    Dies erfolgt auf Grundlage von Art. 6 Abs. 1
                    lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher
                    Maßnahmen gestattet. Personenbezogene Daten über die Inanspruchnahme unserer Internetseiten
                    (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem Nutzer
                    die Inanspruchnahme des Dienstes zu ermöglichen oder abzurechnen.
                </p>
                <p>
                    Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder Beendigung der Geschäftsbeziehung
                    gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
                </p>
            </div>
        </div>
        <div class="col s12 m10 offset-m1 l6">
            <div class="koatn">
                <h3>Datenübermittlung bei Vertragsschluss für Dienstleistungen und digitale Inhalte
                </h3>
                <p>
                    Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen der
                    Vertragsabwicklung notwendig ist, etwa an das mit der Zahlungsabwicklung beauftragte Kreditinstitut.
                </p>
                <p>
                    Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der Übermittlung
                    ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer Daten an Dritte ohne ausdrückliche
                    Einwilligung, etwa zu Zwecken der Werbung, erfolgt nicht.
                </p>
                <p>
                    Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten
                    zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
                </p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col s12 m10 offset-m1 l6">
            <div class="koatn">
                <h3>Google Web Fonts
                </h3>
                <p>
                    Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt
werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
                </p>
                <p>
                    Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
                </p>
                <p>
                    Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt. Weitere Informationen zu Google Web Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a> und in der Datenschutzerklärung von Google: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.
                </p>
            </div>
        </div>
        <div class="col s12 m10 offset-m1 l6">
            <div class="koatn">
                <h3>Google Maps
                </h3>
                <p>
                    Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
                </p>
                <p>
                    Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu speichern. Diese Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.
                </p>
                <p>
                    Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
                </p>
                <p>
                    Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google: <a href="https://www.google.de/intl/de/policies/privacy/">https://www.google.de/intl/de/policies/privacy/</a>.
                </p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col s12 m10 offset-m1 l12">
            <div class="koatn">
                <h3>ÄNDERUNG DIESER DATENSCHUTZBESTIMMUNGEN
                </h3>
                <p>
                    Wir behalten uns das Recht vor, diese Datenschutzbestimmungen jederzeit unter Beachtung der jeweils geltenden Datenschutzrechtlichen Bestimmungen zu ändern.

                </p>
               
            </div>
        </div>
    </div>
</div>