
<div class="master">
    <huama-nav [style.display]="showToolbar ? null : 'none'" [navItems]="navItems"></huama-nav>
    <router-outlet></router-outlet>
    <div [style.display]="showToolbar ? null : 'none'" class="footer">
        <huama-footer></huama-footer>
    </div>
<div id="snow"></div>

</div>

<div *ngIf="!cookiesAccepted"class="toast-wrapper" [ngClass]="{'mobile': isMobile()}">
    <div class="toast rounded toasted">
        <span>
            Diese Seite verwendet Cookies.
        </span>
        <div>
        <button class="btn toast-action" (click)="toast(true)">
            Akzeptieren
        </button>
        <button class="btn-flat toast-action" (click)="toast(false)">
            Nein danke
        </button>
        </div>
    </div>
</div>