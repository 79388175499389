import { AfterContentChecked, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { isScrolledDownEnough } from '../helpers';

declare var M: any;

export type LinkInstance = {routerLink?: any[] | string [] | string | null | undefined,
  queryParams?: Params | null,
  fragment?: string;
  external?: string;
}

@Component({
  selector: 'huama-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, AfterContentChecked, OnDestroy {

  destroy = new Subject<void>();
  destroy$ = this.destroy.asObservable();

  maps: string;

  get transparentNav(): string | null {
    return this._transparentNav;
  }
  
  set transparentNav(y: any) {
    this._transparentNav = y === undefined || y === null || isScrolledDownEnough(y as number) ? null : 'none';
  }

  private _transparentNav: string | null = null;
 
  sideNav: any;

  @Input()
  navItems: Array<{text: string} & LinkInstance> = [];

  constructor() { 
    this.transparentNav = window.scrollY;
    this.maps = this.getDestinationLink()

    fromEvent(window, 'scroll').pipe(takeUntil(this.destroy$))
			.subscribe((e: Event) => {
        const y = this.getYPosition(e);
        this.transparentNav = y;
        
      })
  }

  ngOnDestroy(): void {
    this.sideNav.destroy();
    this.destroy.next();
  }

  ngAfterContentChecked(): void {
    if (this.sideNav === undefined) {
      document.addEventListener('DOMContentLoaded', () => {
        if (this.sideNav === undefined) {
          var elems = document.querySelectorAll('.sidenav');
          var instance = M.Sidenav.init(elems, {
            edge: 'right'
          });
          this.sideNav = M.Sidenav.getInstance(elems[0])
        }
      });
    }
    
  }

  ngOnInit(): void {
  }

  getYPosition(e: Event): number | undefined {
    return (e.target as any)?.scrollingElement?.scrollTop;
  }

  getDestinationLink() {
    // If it's an iPhone..
    if( (navigator.platform.indexOf("iPhone") != -1) 
        || (navigator.platform.indexOf("iPod") != -1)
        || (navigator.platform.indexOf("iPad") != -1))
         return "maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=Biohof%20Huama,%20Untergeng%2078,%204201%20Untergeng";
    return"https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=Biohof%20Huama,%20Untergeng%2078,%204201%20Untergeng";
  }

}
