 
 <nav class="navbar" [style.background]="transparentNav" [style.box-shadow]="transparentNav">
    <div class="nav-wrapper">
      <a [style.opacity]="transparentNav ? 0 : 1" 
      [routerLink]="navItems[0]?.routerLink" class="brand-logo center title">Biohof Huama</a>
      <a href="#" data-target="mobile-demo" class="sidenav-trigger right"><i class="material-icons">menu</i></a>
      <ul class="right hide-on-med-and-down">
        <li *ngFor="let item of navItems">
          <a [routerLink]="item.routerLink" 
          [queryParams]="item.queryParams" [fragment]="item.fragment"
          routerLinkActive="active">{{item.text}}</a>
        </li>
      </ul>
    </div>
  </nav>

  <nav style="visibility: hidden;">
    <div class="nav-wrapper" >
      
    </div>
  </nav>

  <div  class="sidenav" id="mobile-demo">
  <ul >
    <li (click)="sideNav.close()" class=""><a><i class="material-icons" style="float: right; margin: 0;">close</i></a></li>
    <li *ngFor="let item of navItems">
      <a   (click)="sideNav.close()" class="flow-text" [routerLink]="item.routerLink" 
      [queryParams]="item.queryParams" [fragment]="item.fragment"
      routerLinkActive="active">{{item.text}}</a>
    </li>
    <li>
      <a (click)="sideNav.close()" class="flow-text" routerLink="imprint" 
      [queryParams]="undefined" [fragment]="undefined"
      routerLinkActive="active">Impressum</a>
    </li>
    <li>
      <a (click)="sideNav.close()" class="flow-text" routerLink="imprint" 
      [queryParams]="undefined" fragment="dataprivacy"
      routerLinkActive="active">Datenschutz</a>
    </li>
  </ul>
  <div class="wrapper">
    <a href="https://www.instagram.com/biohofhuama/">
      <fa-icon icon="instagram"></fa-icon>
    </a>
    <a href="https://www.facebook.com/BiohofHuama">
      <fa-icon icon="facebook"></fa-icon>
    </a>
    <a [href]="maps">
      <fa-icon [icon]="['fas', 'map']"></fa-icon>
    </a>
  </div>

</div>