import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Product, ProductPage } from './helpers';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  getProductPage(): Observable<ProductPage> {
    return this.http.get<ProductPage>("/assets/produkte/produkte.json");
  }
}
