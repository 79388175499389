import { Component, OnInit } from '@angular/core';
import { isMobileDevice } from '../helpers';

@Component({
  selector: 'huama-anfahrt',
  templateUrl: './anfahrt.component.html',
  styleUrls: ['./anfahrt.component.scss']
})
export class AnfahrtComponent implements OnInit {

  constructor() { }

  isMobile = isMobileDevice(navigator);

  mapheight = "360px"

  maps?: string;

  ngOnInit(): void {

    this.maps = this.getDestinationLink();

  }

  cookies() {
    return JSON.parse(localStorage.getItem("cookies")!) as boolean
  }

  getDestinationLink() {
    // If it's an iPhone..
    if( (navigator.platform.indexOf("iPhone") != -1) 
        || (navigator.platform.indexOf("iPod") != -1)
        || (navigator.platform.indexOf("iPad") != -1))
         return "maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=Biohof%20Huama,%20Untergeng%2078,%204201%20Untergeng";
    return"https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=Biohof%20Huama,%20Untergeng%2078,%204201%20Untergeng";
}

}
