import { Component, OnInit } from '@angular/core';
import { isMobileDevice } from '../helpers';

@Component({
  selector: 'huama-hof',
  templateUrl: './hof.component.html',
  styleUrls: ['./hof.component.scss']
})
export class HofComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.isMobile = isMobileDevice(navigator, 'l')

  }

  isMobile?: boolean;

}