import { isFinite} from "lodash";

const snowFlakeNumber = {
    mobile: 50,
    desktop: 200,
}

const random = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
}

export class Snow {

    canvas: HTMLCanvasElement;

    snowflakes: Snowflake[];

    go: boolean;

    constructor(
        public document: Document, 
        public options: SnowOptions) {

        const element = this.document.getElementById(this.options.id)!;

        element.style.position = "fixed";
        element.style.top = "0";
        element.style.left = "0";
        element.style.right = "0";
        element.style.bottom = "0";
        element.style.zIndex = "9";
        element.style.pointerEvents = "none";

                //create canvas
        this.canvas = document.createElement("canvas"); //add random number to change canvas id
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
        element.appendChild(this.canvas);

        //get theme
        var theme = "default";
        if (this.options.theme) {
            switch(this.options.theme) {
                case "colors":
                case "blues":
                case "watermelon":
                case "berry":
                case "pastel":
                    theme = options.theme!;
            }
        }

        //change size
        let min = 2;
        let max = 7;
        if (isFinite(options.min_size)) {
            min = options.min_size!;
        }
        if (isFinite(options.max_size)) {
            max = options.max_size!;
        }

        //snowflake list
        this.snowflakes = []
        for (let i = 0; i < (this.options.mobile ? snowFlakeNumber.mobile : snowFlakeNumber.desktop) ; i++) {
            this.snowflakes[i] = new Snowflake(this.canvas, theme, min, max);
            this.snowflakes[i].show();
        }

        //boolean is snow is true or false
        this.go = false;
        this.snowfall();
    
    }

    start() {
        this.go = true;
    }

    stop() {
        this.go = false;
    }

    toggle() {
        this.go = !this.go;
    }

    private snowfall() {
        requestAnimationFrame(() => this.snowfall());

        if (this.go) {
            //clear canvas
            const context = this.canvas.getContext('2d')!;
            context.clearRect(0, 0, this.canvas.width, this.canvas.height);

            //update snowflakes
            for (var i = 0; i < (this.options.mobile ? snowFlakeNumber.mobile : snowFlakeNumber.desktop); i++) {
                this.snowflakes[i].update();
                this.snowflakes[i].show();

                if (this.snowflakes[i].y > this.canvas.height) {
                    this.snowflakes[i].y = random(-20, -200);
                }
            }
        }
    }

    private random(min: number, max: number) {
        return Math.random() * (max - min) + min;
    }
}

export interface SnowOptions {
    id: string,
    theme?: string,
    min_size?: number; 
    max_size?: number;
    mobile?: boolean;
}

class Snowflake {

    radius: number;
    x : number;
    y : number;
    Vy: number;
    color: string;

    constructor(
        public canvas: HTMLCanvasElement,
        public theme: string,
        public min: number, 
        public max: number
    ) {
        this.radius = random(min, max);
        this.x = random(0, canvas.width);
        this.y = random(-20, -800);
        this.Vy = random(1, 2)

         //set default
        this.color = "#FFF"

        if (theme == "colors") {
            this.color =
                "rgb(" +
                Math.floor(Math.random() * 256) +
                "," +
                Math.floor(Math.random() * 256) +
                "," +
                Math.floor(Math.random() * 256) +
                ")";
        } else if (theme == "blues") {
            this.color =
                "rgb(" +
                0 +
                "," +
                0 +
                "," +
                Math.floor(Math.random() * 256) +
                ")";
        } else if (theme == "watermelon") {
            if (Math.random() < 0.5) {
                this.color =
                    "rgb(" +
                    random(242, 255) +
                    "," +
                    random(0, 50) +
                    "," +
                    random(70, 120) +
                    ")";
            } else {
                this.color =
                    "rgb(" +
                    0 +
                    "," +
                    Math.floor(Math.random() * 256) +
                    "," +
                    0 +
                    ")";
            }
        } else if (theme == "berry") {
            this.color =
                "rgb(" +
                random(40, 150) +
                "," +
                random(0, 50) +
                "," +
                random(80, 180) +
                ")";
        } else if (theme == "pastel") {
            this.color =
                "hsla(" +
                random(0, 360) +
                "," +
                random(40, 80) +
                "%," +
                random(60, 80) +
                "%)";
        }

        this.canvas = canvas;

    }

    show() {
        let ctx = this.canvas.getContext("2d")!;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.radius, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.fillStyle = this.color;
        ctx.fill();
    }

    update() {
        this.y += this.Vy;
    }
}

