import { Component, OnInit, Input } from '@angular/core';
import { isMobileDevice } from '../helpers';

@Component({
  selector: 'huama-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.scss']
})
export class KontaktComponent implements OnInit {

  constructor() { }

  isMobile = isMobileDevice(navigator);

  @Input() impressum: boolean = true;

  ngOnInit(): void {
  }

}
