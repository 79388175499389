
<huama-blank *ngIf="!isMobile"></huama-blank>

<div class="container">
    <div class="row">
        <div class="col s12  center  huamerisch">
            <div class="titl">
                <div class="koatn">
                    <h1>Unser Hof</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        
        <div class="col s12 m10 l8 offset-l2 offset-m1 nopadding abrundn-links abrundn-rechts">
            <img class="product-image abrundn-rechts"  width="100%" src="assets/hof/main.jpeg"/>
        </div>
    </div>
    <div class="row" [ngClass]="{'valign-wrapper': !isMobile}">
        <div class="koatn col s12 m10 l8 offset-l2 offset-m1 center section">
            <p class="flow-text">
                Mitten im schönen Mühlviertel.

            </p>
            <p class="flow-text">
                In der ruhigen Ortschaft Untergeng.
            </p>
            <p class="flow-text">
                Auf 640 Meter Seehöhe. 
            </p>
            <p class="flow-text">
                Mit purer Lebensqualität.
            </p>
            <p class="flow-text">
                Seit 2008 biologisch zertifiziert. 
            </p>

        </div>
        
    </div>
</div>

<div class="container products">
    <div class="row koatn" [ngClass]="{'valign-wrapper': !isMobile}">
        <div  class="col s12 l6 xl5 nopadding">
            <img class="product-image abrundn-links" width="100%" src="assets/hof/hehnastoi.jpeg"/>
        </div>
        
        <div  class="col s12 l6 xl7">
            <div  class="text-col section">
            <h3 class="huamerisch">Hehnastoi</h3>
            <p class="">
                Im März 2020 starteten Melanie und Florian erstmals mit Legehennen in einem selbst gebauten Mobilstall. Die Nachfrage war groß und so investierten sie 2021 in einen größeren mobilen Hühnerstall.
            </p>
            <p>
                Dieser Hehnastoi von der Firma Mitterbauer ist für 300 Bio-Legehennen zugelassen. Erstmals wurde ein Hehnastoi mit einem hydraulischen Fahrwerk ausgestattet, weil es bei uns sehr hügelig ist. Die Firma Mitterbauer hatte so etwas noch nicht im Angebot und so zeichneten wir es mit einem Bekannten selbst. Anschließend hat uns die Firma Mitterbauer das hydraulische Fahrwerk auf den Stall gebaut. Außerdem ist der Stall mit Photovoltaik, Handysteuerung, Wassertank, automatischer Fütterung und automatischen Austriebklappen ausgestattet. 
            </p>
            </div>
        </div>

    </div>

    <div class="row koatn" [ngClass]="{'valign-wrapper': !isMobile}">
        <div  class="col s12 l6 xl5 nopadding">
            <img class="product-image abrundn-links" width="100%" src="assets/hof/ochs.jpg"/>
        </div>
        
        <div  class="col s12 l6 xl7">
            <div  class="text-col section">
            <h3 class="huamerisch">Bio-Ochsen</h3>
            <p class="">
                2003 haben Rosi und Leo von Milchkühe auf Ochsenmast umgestellt. Seitdem werden jährlich 14 Ochsen eingestellt. Nach zwei Jahren werden sie zu Bio-Rindfleisch verarbeitet.             </p>
            <p>
                Die Ochsen sind im ersten Sommer durchgehend auf der Weide und ernähren sich dort vom frischen Gras und Heu. Als Unterstand haben sie den Wald. Im Winter dürfen sie dann natürlich in den Stall, wobei die Türen zum Auslauf immer offen stehen. Im zweiten Jahr sind sie dann im Stall und auf der Weide, wobei sie immer frei entscheiden dürfen, wo sie sich aufhalten.             </p>
            </div>
        </div>

    </div>

    <div class="row koatn" [ngClass]="{'valign-wrapper': !isMobile}">
        <div  class="col s12 l6 xl5 nopadding">
            <img class="product-image abrundn-links" width="100%" src="assets/hof/ziegen.jpg"/>
        </div>
        
        <div class="col s12 l6 xl7">
            <div  class="text-col section">
            <h3 class="huamerisch">Maggie und Bella</h3>
            <p class="">
                Unsere beiden Zwergziegen sind die Bewacherinnen des mobilen Hühnerstalls. Wir sind ihnen sehr dankbar, denn seitdem sie bei uns am Hof sind, halten sie die Greifvögel erfolgreich fern.            </p>
            </div>
        </div>

    </div>

    <div class="row koatn" [ngClass]="{'valign-wrapper': !isMobile}">
        <div  class="col s12 l6 xl5 nopadding">
            <img class="product-image abrundn-links" width="100%" src="assets/hof/krautland.jpg"/>
        </div>
        
        <div class="col s12 l6 xl7">
            <div  class="text-col section">
            <h3 class="huamerisch">„Krautland“</h3>
            <p class="">
                Für den Eigengebrauch bauen wir dort immer schon Kartoffeln und Gemüse an, um uns über den Winter selbst versorgen zu können. Unter anderem machen wir Sauerkraut. 
                Heuer haben wir die Fläche erstmals vergrößert, um mehr Kartoffeln und Knoblauch anbauen zu können. Leider hat uns das Wetter mit dem Hagel und Überschwemmungen einen Strich durch die Rechnung gemacht und somit ist der Ertrag etwas geringer ausgefallen. Dennoch gibt es noch Kartoffeln in der Selbstbedienungshütte. Der Knoblauch ist bereits ausverkauft.
                                            </div>
        </div>

    </div>

    <div *ngIf="true" class="row" [ngClass]="{'valign-wrapper': !isMobile}">

        <div class="col s12 offset-l3 l6 nopic" [ngClass]="{'mobile': isMobile}">
            <div class="section text-col koatn">

            <h3 class="huamerisch">Schweine</h3>
            <p class="">
                Für den Eigengebrauch haben wir jährlich zwei Schweine
        </div>
    </div>
    </div>    

   
</div>