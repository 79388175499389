import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { isMobileDevice } from '../helpers';
import { DataService } from '../data.service';
import { map } from 'rxjs';

@Component({
  selector: 'huama-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  constructor(private data: DataService) { }
  
  page = this.data.getProductPage().pipe(
    map(page => {
      page.img = page.img.map(img => `assets/produkte/${img}`);
      page.products = page.products.map(product => {
        product.img = product.img?.map(img => `assets/produkte/${img}`);
        return product;
      })
      return page;
    })
  );

  produkte = this.page.pipe(
    map((produktPage) => {
      return produktPage.products.filter((produkt) => produkt.img)
    })
  )

  produkteOhneFoto = this.page.pipe(
    map((produktPage) => {
      return produktPage.products.filter((produkt) => !produkt.img)
    })
  )

  ngOnInit(): void {
    this.isMobile = isMobileDevice(navigator, 'l')
  }

  isMobile?: boolean;

}
