import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { NavComponent } from './nav/nav.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { BlankComponent } from './blank/blank.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { ProductsComponent } from './products/products.component';
import { LogoComponent } from './logo/logo.component';
import { AnfahrtComponent } from './anfahrt/anfahrt.component';
import { HofComponent } from './hof/hof.component';
import { SoonComponent } from './soon/soon.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebook, faFacebookSquare, faFacebookF, faGoogle, faInstagram, faYoutube, faYoutubeSquare, faInstagramSquare } from '@fortawesome/free-brands-svg-icons'
import { faMap } from '@fortawesome/free-solid-svg-icons';
import { DataService } from './data.service';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    KontaktComponent,
    NavComponent,
    ImpressumComponent,
    NotFoundComponent,
    BlankComponent,
    FooterComponent,
    AboutComponent,
    ProductsComponent,
    LogoComponent,
    AnfahrtComponent,
    HofComponent,
    SoonComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
   NgxUsefulSwiperModule,
   FontAwesomeModule,
   HttpClientModule
  ],
  providers: [DataService],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(library: FaIconLibrary) {
    library.addIcons(
      faFacebook, faMap,
      faInstagram,
    )
  }

 }
