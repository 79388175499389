<huama-blank *ngIf="!isMobile"></huama-blank>
<div class="container">
  <div class="row">
    <div class="col s12 center huamerisch">
      <div class="titl">
        <div class="koatn">
          <h1>Über Uns</h1>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="row">
    <div
      class="
        col
        s12
        m10
        offset-m1
        nopadding
        abrundn-links abrundn-rechts
      "
    >
      <swiper [config]="config">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="assets/about/slide/01.jpg" />
          </div>
          <div class="swiper-slide">
            <img src="assets/about/slide/02.jpg" />
          </div>
          <div class="swiper-slide">
            <img src="assets/about/slide/03.jpg" />
          </div>

          <div class="swiper-slide">
            <img src="assets/about/slide/04.jpg" />
          </div>
          <div class="swiper-slide">
            <img src="assets/about/slide/05.jpg" />
          </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
        <!-- Add Arrows -->
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </swiper>
    </div>
  </div>
  <div class="row" [ngClass]="{ 'valign-wrapper': !isMobile }">
    <div class="koatn col s12 m10 offset-m1 center section">
      <p class="flow-text">Das sind wir: Florian & Melanie, mit Florians Eltern Rosi und Leo</p>
    </div>
  </div>
</div>

<div class="container products">
  <div class="row koatn" [ngClass]="{ 'valign-wrapper': !isMobile }">
    <div class="col s12 l6 xl5 nopadding">
      <img
        class="product-image abrundn-links"
        width="100%"
        src="assets/about/Rosi.jpg"
      />
    </div>

    <div class="col s12 l6 xl7">
      <div class="text-col section">
        <h3 class="huamerisch">Rosi...</h3>
        <ul>
          <li>...ist die Expertin für Rindfleisch und Gemüse.</li>
          <li>...versorgt die Familie täglich mit gutem Essen.</li>
          <li>...arbeitet in der Autofirma ihres ältesten Sohnes.</li>
          <li>...ist warmherzig, hilfsbereit, humorvoll und offen.</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row koatn" [ngClass]="{ 'valign-wrapper': !isMobile }">
    <div class="col s12 l6 xl5 nopadding">
      <img
        class="product-image abrundn-links"
        width="100%"
        src="assets/about/Leo.jpg"
      />
    </div>

    <div class="col s12 l6 xl7">
      <div class="text-col section">
        <h3 class="huamerisch">Leopold...</h3>
        <ul>
          <li>...arbeitet als technischer Angestellter im Neuromed Campus.</li>
          <li>...kümmert sich am Hof um die Ochsen.</li>
          <li>...ist Mann für alles und hat immer die passende Lösung – egal für welches Problem.</li>
          <li>...bringt als Ruhepol stets Gelassenheit ein.</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row koatn" [ngClass]="{ 'valign-wrapper': !isMobile }">
    <div class="col s12 l6 xl5 nopadding">
      <img
        class="product-image abrundn-links"
        width="100%"
        src="assets/about/Florian.jpg"
      />
    </div>

    <div class="col s12 l6 xl7">
      <div class="text-col section">
        <h3 class="huamerisch">Florian...</h3>
        <ul>
          <li>...ist gelernter Landmaschinentechniker im Lagerhaus. </li>
          <li>...hilft mit großem Engagement in der Landwirtschaft seiner Eltern mit.</li>
          <li>...hat sich mit den Legehennen ein zweites Standbein aufgebaut und ist für die Versorgung der Hühner zuständig.</li>
          <li>...ist verliebt in seine Melanie – und in Traktoren ;)</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row koatn" [ngClass]="{ 'valign-wrapper': !isMobile }">
    <div class="col s12 l6 xl5 nopadding">
      <img
        class="product-image abrundn-links"
        width="100%"
        src="assets/about/Melanie.jpg"
      />
    </div>

    <div class="col s12 l6 xl7">
      <div class="text-col section">
        <h3 class="huamerisch">Melanie...</h3>
        <ul>
          <li>...hat die HBLA Elmberg erfolgreich absolviert und studiert nun Gesundheits- und Krankenpflege.</li>
          <li>...ist auf einer biologischen Landwirtschaft aufgewachsen.</li>
          <li>...spürt als kreativer Geist große Motivation für die Direktvermarktung.</li>
          <li>...holt jeden Tag die Eier und ist für das Befüllen der Selbstbedienungshütte zuständig.</li>
        </ul>
      </div>
    </div>
  </div>
</div>
