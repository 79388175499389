import { Component, OnInit, ViewChild } from '@angular/core';
import { SwiperComponent } from 'ngx-useful-swiper';
import { SwiperOptions } from 'swiper';
import { isMobileDevice } from '../helpers';
import { FaConfig } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'huama-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor() { }

 // @ViewChild('usefulSwiper',{static: false}) usefulSwiper: SwiperComponent;


  ngOnInit(): void {
    this.isMobile = isMobileDevice(navigator, 'l')

    this.config = {
      autoplay: {
        disableOnInteraction: false,
        delay:  10000
      }, // Autoplay option having value in milliseconds
  
      autoHeight: true,
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets'
      },
  
      fadeEffect: {
        crossFade: true,
      },
    };

  }

  config: SwiperOptions = {};

  isMobile?: boolean;
}
